import React, { useMemo } from 'react';
import styles from './index.module.scss';

import { useSelector } from 'react-redux';
import { EditIcon, InfoIcon } from '@assets/icons';
import MissIcon from '@assets/sprites/missing.png';

import { Popover } from 'shopee-ui-react';

const Legends = () => {
  const config = useSelector((state: any) => state.config);
  const query = { fixDuration: 'false' };

  const { softLowerLimit, softUpperLimit, durationUpperLimit } = useMemo(() => {
    const legendConfig = config.legendConfig;
    const {
      softLowerLimit = 0,
      softUpperLimit = 0,
      durationUpperLimit = 0,
    } = legendConfig;
    return {
      softLowerLimit,
      softUpperLimit,
      durationUpperLimit,
    };
  }, [config.legendConfig]);

  const fixDuration = useMemo(() => {
    return query.fixDuration === 'true';
  }, [query.fixDuration]);

  const redText = useMemo(() => {
    return 'Missing Record';
  }, [fixDuration, softLowerLimit]);

  const orangeText = useMemo(() => {
    return fixDuration
      ? '0 < Duration < 8.5h'
      : `0 < Duration < ${softLowerLimit}h`;
  }, [fixDuration, softLowerLimit]);

  const lightBlueText = useMemo(() => {
    return fixDuration
      ? '8.5h ≤ Duration < 9.5h'
      : `${softLowerLimit}h ≤ Duration < ${softUpperLimit}h`;
  }, [fixDuration, softLowerLimit, softUpperLimit]);

  const blueText = useMemo(() => {
    return fixDuration
      ? '9.5h ≤ Duration < 11.5h'
      : `${softUpperLimit}h ≤ Duration < ${durationUpperLimit}h`;
  }, [fixDuration, softUpperLimit, durationUpperLimit]);

  const darkBlueText = useMemo(() => {
    return fixDuration
      ? 'Duration ≥ 11.5h'
      : `Duration ≥ ${durationUpperLimit}h`;
  }, [durationUpperLimit, fixDuration]);

  const handleBack = () => {
    window.history.back();
  };

  return (
    <Popover
      placement="rightTop"
      trigger="hover"
      content={
        <div>
          <div className={styles.legend_inner_wrapper}>
            <div className={styles.item}>
              <div className={`${styles.missing}`}>
                <img src={MissIcon} />
              </div>

              <div className={styles.text}>{redText}</div>
            </div>

            <div className={styles.item}>
              <div className={`${styles.dot} ${styles.orange}`}></div>
              <div className={styles.text}>{orangeText}</div>
            </div>

            <div className={styles.item}>
              <div className={`${styles.dot} ${styles.lightBlue}`}></div>
              <div className={styles.text}>{lightBlueText}</div>
            </div>

            <div className={styles.item}>
              <div className={`${styles.dot} ${styles.blue}`}></div>
              <div className={styles.text}>{blueText}</div>
            </div>

            <div className={styles.item}>
              <div className={`${styles.dot} ${styles.darkBlue}`}></div>
              <div className={styles.text}>{darkBlueText}</div>
            </div>

            <div className={styles.item}>
              <EditIcon className={styles.edit} />
              <div className={styles.text}>Corrected By User</div>
            </div>
          </div>
        </div>
      }>
      <div className={styles.legend_outter_wrapper}>
        <InfoIcon />
        <div className={styles.label}>Legends</div>
      </div>
    </Popover>
  );
};
export default Legends;
