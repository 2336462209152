import {
  addExemption,
  addMemo,
  exportMyAttendanceList,
  getAttendanceMemoConfig,
  getMyAttendanceList,
} from '@api/index';
import { AttendanceGroupFeedResponse, MyAttendanceRequest } from '@api/type';
import UserCalendar from '@components/EchartCalendar';
import MemoModal from '@components/UserCalender/MemoModal';
import {
  CalendarRangeMap,
  rangeShortcuts,
  SubordinateViewTypeEnum,
} from '@contants/index';
import { UserInfoState } from '@reducers/userInfo/type';
import dayjs from 'dayjs';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Button, DatePicker, Modal, Toast } from 'shopee-ui-react';
import styles from './index.module.scss';
import Legends from '@components/Legend';
import { cloneDeep } from 'lodash-es';
import { downLoadFile } from '@util/index';
import { markBizEnd, markBizStart } from 'common/monitor-js-sdk';
import { bizMetricsMap } from 'common/monitor-js-sdk/biz-metrics';
import { ConfigState } from '@reducers/config/type';

const getDefaultParams = (): MyAttendanceRequest => {
  const endDate = dayjs().format('YYYY-MM-DD');
  const startDate = dayjs().subtract(30, 'day').format('YYYY-MM-DD');

  return {
    startDate,
    endDate,
  };
};

const MyAttendance = () => {
  const searchParamsRef = useRef<MyAttendanceRequest>(getDefaultParams());
  const functionConfig = useSelector(
    (state: { config: ConfigState }) => state.config.functionConfig
  );

  const [data, setData] = useState<AttendanceGroupFeedResponse>({});
  const [memoList, setMemoList] = useState<string[]>([]);
  const [memo, setMemo] = useState({
    reason: '',
    remark: '',
  });
  const [memoModalVisible, setMemoModalVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [params, setParams] = useState<MyAttendanceRequest>(getDefaultParams());
  const [isExporting, setIsExporting] = useState(false);

  const storeRef = useRef({
    clickDay: {} as any,
  });

  const userInfo = useSelector(
    (store: { userInfo: UserInfoState }) => store.userInfo
  );

  const handleAddExemption = async (staff: any) => {
    let op = 1;
    if (staff.exempt) {
      op = 0;
    }
    const { ret, exemptBy, exemptDate } = await addExemption({
      id: staff.id,
      op,
    });
    if (ret === 0) {
      const info = data.attendance?.filter(item => item.id === staff.id)[0];
      if (info) {
        info.exempt = !staff.exempt;
        info.exemptBy = exemptBy;
        info.exemptDate = exemptDate;
      }
      setData(cloneDeep(data));
    }
  };

  const handleOpenAddMemo = (day: any) => {
    if (memoList.includes(day.memo)) {
      setMemo({
        reason: day.memo,
        remark: '',
      });
    } else if (day.memo && !memoList.includes(day.memo)) {
      setMemo({
        reason: 'Others',
        remark: day.memo,
      });
    } else {
      setMemo({
        reason: '',
        remark: '',
      });
    }
    storeRef.current.clickDay = day;
    setMemoModalVisible(true);
  };

  const handleMemoModalConfirm = async () => {
    let memoStr = memo.reason;
    if (memo.reason === 'Others') {
      memoStr = memo.remark;
    }
    storeRef.current.clickDay.memo = memoStr;
    const { ret } = await addMemo({
      id: storeRef.current.clickDay.id,
      memo: memoStr,
      day: storeRef.current.clickDay.day,
    });
    if (ret === 0) {
      // 找到对应的员工和日期，更新 memo
      const staffInfo = data.attendance && data.attendance[0];
      if (staffInfo) {
        const targetDay = staffInfo.days.find(
          day => day.day === storeRef.current.clickDay.day
        );
        if (targetDay) {
          targetDay.memo = memoStr;
          setData(cloneDeep(data));
        }
      }
    }
    setMemoModalVisible(false);
  };

  const handleRangeChange = (range: any) => {
    if (!range) {
      setParams(prev => ({
        ...prev,
        startDate: '',
        endDate: '',
      }));
      return;
    }
    const { startDate, endDate } = range;
    setParams(prev => ({
      ...prev,
      startDate: dayjs(startDate).format('YYYY-MM-DD'),
      endDate: dayjs(endDate).format('YYYY-MM-DD'),
    }));
  };

  const fetchData = async (searchParams: MyAttendanceRequest) => {
    try {
      setLoading(true);
      const {
        data: response,
        code,
        message,
      } = await getMyAttendanceList(searchParams);
      setData(response);
      if (code !== 0) {
        Toast.error(message);
      }
      markBizEnd({
        targetType: bizMetricsMap['view-user-attendance-list'],
        code,
        isSuccess: 0,
      });
    } catch (error: any) {
      Toast.error('Failed to fetch attendance data');
      markBizEnd({
        targetType: bizMetricsMap['view-user-attendance-list'],
        code: error?.code,
        status: error?.status,
        isSuccess: 2,
      });
    } finally {
      setLoading(false);
    }
  };

  const handleSubmit = () => {
    fetchData(params);
    // 更新导出列表参数引用
    searchParamsRef.current = params;
  };

  const handleReset = () => {
    const defaultParams = getDefaultParams();
    setParams(defaultParams);
    // 更新导出列表参数引用
    searchParamsRef.current = defaultParams;
    fetchData(defaultParams);
  };

  const handleExport = async () => {
    try {
      setIsExporting(true);
      // 使用 ref 中存储的实际查询参数
      const res = await exportMyAttendanceList({
        ...searchParamsRef.current,
      });
      downLoadFile(res);
    } catch (err) {
      Modal.alarm({
        title: 'Notification',
        content: 'Export Attendance Report Error',
      });
    } finally {
      setIsExporting(false);
    }
  };

  useEffect(() => {
    performance.mark('FCP');
    handleSubmit();
    markBizStart(bizMetricsMap['view-user-attendance-list']);
    getAttendanceMemoConfig().then(res => {
      setMemoList(res.memos);
    });
  }, []);

  const [hasFilterPermission, hasExportPermission] = useMemo(() => {
    const functionItem = functionConfig.filter(
      item => item.name === 'General'
    )[0];
    const filterFunction =
      functionItem.subPermissions &&
      functionItem.subPermissions.filter(
        item => item.name === 'show_filter'
      )[0];
    const exportFunction =
      functionItem.subPermissions &&
      functionItem.subPermissions.filter(
        item => item.name === 'show_export'
      )[0];

    return [filterFunction?.hasPerm || false, exportFunction?.hasPerm || false];
  }, [functionConfig]);

  return (
    <div className={styles.my_attendance}>
      <div className={styles.title_wrapper}>My Attendance</div>
      <div className={styles.header_wrapper}>
        <div className={styles.legend_wrapper}>
          <Legends />
        </div>
        {hasFilterPermission ? (
          <div className={styles.filter_wrapper}>
            <div className={styles.filter_item}>
              <div className={styles.filter_label}>Date:</div>
              <div
                className={`${styles.filter_component} ${styles.date_picker}`}>
                <DatePicker
                  min={new Date(new Date().getFullYear() - 2, 0, 1)}
                  max={new Date()}
                  value={
                    params.startDate && params.endDate
                      ? {
                          startDate: new Date(params.startDate),
                          endDate: new Date(params.endDate),
                        }
                      : undefined
                  }
                  size="small"
                  type="daterange"
                  clearable={false}
                  shortcuts={rangeShortcuts}
                  onChange={handleRangeChange}
                />
              </div>
            </div>
            <div className={styles.filter_action}>
              <Button
                className={styles.submit_button}
                size="small"
                type="primary"
                onClick={handleSubmit}>
                Submit
              </Button>
              <Button
                size="small"
                onClick={handleReset}>
                Reset
              </Button>
            </div>
          </div>
        ) : null}
      </div>
      {hasExportPermission ? (
        <div className={styles.export_wrapper}>
          <Button
            size="small"
            disabled={!data.attendance?.length || isExporting}
            loading={isExporting}
            onClick={handleExport}>
            Export
          </Button>
        </div>
      ) : null}
      <UserCalendar
        {...data}
        myAttendance={true}
        addExemption={handleAddExemption}
        openAddMemo={handleOpenAddMemo}
        loading={loading}
      />
      <MemoModal
        visible={memoModalVisible}
        memoList={memoList}
        reason={memo.reason}
        remark={memo.remark}
        confirm={handleMemoModalConfirm}
        reasonChange={reason => setMemo({ ...memo, reason })}
        remarkChange={remark => setMemo({ ...memo, remark })}
        setVisible={setMemoModalVisible}
      />
    </div>
  );
};

export default MyAttendance;
