import dayjs from 'dayjs';

export const logout = (email?: string) => {
  if (email) {
    window.location.href = '/logout?email=' + email;
  } else {
    window.location.href = '/logout';
  }
};

export const handleCascaderOptions = (arr: any) => {
  return arr.map(function (item: any) {
    const newItem = {
      value: item.id,
      label: item.name,
      children: handleCascaderOptions(item.subList),
    };
    return newItem;
  });
};

export const formatDate = (
  timestamp: number,
  format = 'DD MMM YYYY, HH:mm'
) => {
  return dayjs(timestamp).format(format);
};

export const downLoadFile = (res: any) => {
  const data = res.data;
  const headers = res.headers;
  const regex = /filename="([^"]+)"/;
  const match = regex.exec(headers['content-disposition']);
  let filename = 'Attendance Report.csv';
  if (match && match[1]) {
    filename = match[1];
  }

  const blob = new Blob([data], { type: 'application/octet-stream' });
  // 创建下载链接
  const downloadLink = document.createElement('a');
  downloadLink.href = window.URL.createObjectURL(blob);
  downloadLink.download = filename;

  // 触发下载
  downloadLink.click();
};
