import {
  addExemption,
  addMemo,
  exportMyTeamAttendanceList,
  getAttendanceGroupFeeds,
  getAttendanceMemoConfig,
  getMyTeamAttendanceList,
} from '@api/index';
import {
  AttendanceGroupFeedResponse,
  MyTeamAttendanceListRequest,
  MyTeamAttendanceListResponse,
} from '@api/type';
import UserCalendar from '@components/EchartCalendar';
import MemoModal from '@components/UserCalender/MemoModal';
import UserCalendarFilter from '@components/UserCalender/UserCalendarFilter';
import {
  CalendarRangeMap,
  rangeShortcuts,
  SubordinateViewTypeEnum,
} from '@contants/index';
import { UserInfoState } from '@reducers/userInfo/type';
import { markBizEnd, markBizStart } from 'common/monitor-js-sdk';
import { bizMetricsMap } from 'common/monitor-js-sdk/biz-metrics';
import dayjs from 'dayjs';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import {
  Button,
  DatePicker,
  Input,
  Modal,
  Pagination,
  Search,
  Select,
  Toast,
} from 'shopee-ui-react';
import styles from './index.module.scss';
import Legends from '@components/Legend';
import { cloneDeep } from 'lodash-es';
import { downLoadFile } from '@util/index';
import { ConfigState } from '@reducers/config/type';

const getDefaultPageInfo = () => {
  return {
    pageSize: 50,
    total: 0,
    current: 1,
  };
};

const getDefaultParams = (): MyTeamAttendanceListRequest => {
  const endDate = dayjs().format('YYYY-MM-DD');
  const startDate = dayjs().subtract(30, 'day').format('YYYY-MM-DD');

  return {
    keyword: '',
    subordinateViewType: SubordinateViewTypeEnum.DirectSubordinates,
    startDate,
    endDate,
  };
};

const MyTeam = () => {
  const [data, setData] = useState<MyTeamAttendanceListResponse>();
  const [loading, setLoading] = useState(false);
  const [isExporting, setIsExporting] = useState(false);

  const [pageInfo, setPageInfo] = useState(getDefaultPageInfo());
  const [params, setParams] = useState<MyTeamAttendanceListRequest>(
    getDefaultParams()
  );
  const functionConfig = useSelector(
    (state: { config: ConfigState }) => state.config.functionConfig
  );
  const handleStaffInputChange = (value: string) => {
    setParams(prev => ({
      ...prev,
      keyword: value,
    }));
  };

  const [hasFilterPermission, hasExportPermission] = useMemo(() => {
    const functionItem = functionConfig.filter(
      item => item.name === 'Manager'
    )[0];
    const filterFunction =
      functionItem.subPermissions &&
      functionItem.subPermissions.filter(
        item => item.name === 'show_filter'
      )[0];
    const exportFunction =
      functionItem.subPermissions &&
      functionItem.subPermissions.filter(
        item => item.name === 'show_export'
      )[0];

    return [filterFunction?.hasPerm || false, exportFunction?.hasPerm || false];
  }, [functionConfig]);

  const handleViewChange = (value: number) => {
    setParams(prev => ({
      ...prev,
      subordinateViewType: value,
    }));
  };

  const handleRangeChange = (range: any) => {
    if (!range) {
      setParams(prev => ({
        ...prev,
        startDate: '',
        endDate: '',
      }));
      return;
    }
    const { startDate, endDate } = range;
    setParams(prev => ({
      ...prev,
      startDate: dayjs(startDate).format('YYYY-MM-DD'),
      endDate: dayjs(endDate).format('YYYY-MM-DD'),
    }));
  };

  const searchParamsRef = useRef<MyTeamAttendanceListRequest>(
    getDefaultParams()
  );

  const fetchData = useCallback(
    async (
      searchParams: MyTeamAttendanceListRequest,
      page: typeof pageInfo
    ) => {
      try {
        setLoading(true);
        const {
          data: response,
          code,
          message,
        } = await getMyTeamAttendanceList({
          ...searchParams,
          pageSize: page.pageSize,
          pageNum: page.current,
        });

        setData(response);

        if (code !== 0) {
          Toast.error(message);
        }

        markBizEnd({
          targetType: bizMetricsMap['view-team-attendance-list'],
          code,
          isSuccess: 0,
        });

        setPageInfo(prev => ({
          ...prev,
          total: (response.pageInfo && response.pageInfo.total) || 0,
        }));
      } catch (error: any) {
        Toast.error('Failed to fetch attendance data');
        markBizEnd({
          targetType: bizMetricsMap['view-team-attendance-list'],
          code: error?.code,
          status: error?.status,
          isSuccess: 2,
        });
      } finally {
        console.log('???');
        setLoading(false);
      }
    },
    []
  );

  const handleSubmit = () => {
    const newPageInfo = {
      ...pageInfo,
      current: 1,
    };
    setPageInfo(newPageInfo);
    fetchData(params, newPageInfo);
    // 更新导出列表参数引用
    searchParamsRef.current = params;
  };

  const handleReset = () => {
    const defaultParams = getDefaultParams();
    const defaultPageInfo = getDefaultPageInfo();

    setParams(defaultParams);
    setPageInfo(defaultPageInfo);
    // 更新导出列表参数引用
    searchParamsRef.current = defaultParams;
    fetchData(defaultParams, defaultPageInfo);
  };

  const handlePageChange = (current: number) => {
    const newPageInfo = {
      ...pageInfo,
      current,
    };
    setPageInfo(newPageInfo);
    fetchData(params, newPageInfo);
  };

  const handlePageSizeChange = (pageSize: number) => {
    const newPageInfo = {
      ...pageInfo,
      pageSize,
    };
    setPageInfo(newPageInfo);
    fetchData(params, newPageInfo);
  };

  const handleAddExemption = async (staff: any) => {
    let op = 1;
    if (staff.exempt) {
      op = 0;
    }
    const { ret, exemptBy, exemptDate } = await addExemption({
      id: staff.id,
      op,
    });
    if (ret === 0) {
      const info =
        data && data.attendance?.filter(item => item.id === staff.id)[0];
      if (info) {
        info.exempt = !staff.exempt;
        info.exemptBy = exemptBy;
        info.exemptDate = exemptDate;
      }
      setData(cloneDeep(data));
    }
  };

  const handleExport = async () => {
    try {
      setIsExporting(true);
      // 使用 ref 中存储的实际查询参数
      const res = await exportMyTeamAttendanceList({
        ...searchParamsRef.current,
        pageNum: undefined,
        pageSize: undefined,
      });
      downLoadFile(res);
    } catch (err) {
      Modal.alarm({
        title: 'Notification',
        content: 'Export Attendance Report Error',
      });
    } finally {
      setIsExporting(false);
    }
  };

  useEffect(() => {
    performance.mark('FCP');
    handleSubmit();
    markBizStart(bizMetricsMap['view-team-attendance-list']);
  }, []);

  return (
    <div className={styles.my_team}>
      <div className={styles.title_wrapper}>My Team</div>
      <div className={styles.header_wrapper}>
        <div className={styles.legend_wrapper}>
          <Legends />
        </div>
        {hasFilterPermission ? (
          <div className={styles.filter_wrapper}>
            <div className={styles.filter_item}>
              <div className={styles.filter_label}>Staff:</div>
              <div className={styles.filter_component}>
                <Search
                  placeholder="Staff ID, Name or Email"
                  value={params.keyword}
                  size="small"
                  onSearch={handleSubmit}
                  onChange={val => handleStaffInputChange(val as string)}
                />
              </div>
            </div>
            <div className={styles.filter_item}>
              <div className={styles.filter_label}>Subordinate View:</div>
              <div className={styles.filter_component}>
                <Select
                  value={params.subordinateViewType}
                  size="small"
                  onChange={val => handleViewChange(val as number)}>
                  <Select.Option
                    value={SubordinateViewTypeEnum.DirectSubordinates}>
                    Direct Subordinates
                  </Select.Option>
                  <Select.Option
                    value={SubordinateViewTypeEnum.IndirectSubordinates}>
                    Indirect Subordinates
                  </Select.Option>
                  <Select.Option
                    value={SubordinateViewTypeEnum.AllSubordinates}>
                    All Subordinates
                  </Select.Option>
                </Select>
              </div>
            </div>
            <div className={styles.filter_item}>
              <div className={styles.filter_label}>Date:</div>
              <div
                className={`${styles.filter_component} ${styles.date_picker}`}>
                <DatePicker
                  min={new Date(new Date().getFullYear() - 2, 0, 1)}
                  max={new Date()}
                  clearable={false}
                  value={
                    params.startDate && params.endDate
                      ? {
                          startDate: new Date(params.startDate),
                          endDate: new Date(params.endDate),
                        }
                      : undefined
                  }
                  size="small"
                  type="daterange"
                  shortcuts={rangeShortcuts}
                  onChange={handleRangeChange}
                />
              </div>
            </div>
            <div className={styles.filter_action}>
              <Button
                className={styles.submit_button}
                size="small"
                type="primary"
                onClick={handleSubmit}>
                Submit
              </Button>
              <Button
                size="small"
                onClick={handleReset}>
                Reset
              </Button>
            </div>
          </div>
        ) : null}
      </div>
      {hasExportPermission ? (
        <div className={styles.export_wrapper}>
          <Button
            size="small"
            disabled={!pageInfo.total || isExporting}
            loading={isExporting}
            onClick={handleExport}>
            Export
          </Button>
        </div>
      ) : null}
      <UserCalendar
        {...data}
        loading={loading}
        addExemption={handleAddExemption}></UserCalendar>
      <Pagination
        className={styles.pagination}
        {...pageInfo}
        onChange={handlePageChange}
        onSizeChange={handlePageSizeChange}
        layout="pages,sizes,jumper"
      />
    </div>
  );
};

export default MyTeam;
