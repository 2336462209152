import { createBrowserRouter, redirect } from 'react-router-dom';
import { Router } from '@remix-run/router';
import NoPermission from '@components/NoPermission';
import {
  getPermissionConfig,
  notificationConfirm,
  postGroupList,
} from '@api/index';
import { ErrorCode } from '@contants/errorCode';
import React from 'react';
import { logout } from '@util/index';
import { Modal, Toast } from 'shopee-ui-react';
import MyAttendance from '@pages/MyAttendance';
import MyTeam from '@pages/MyTeam';
import AttendanceReport from '@pages/AttendanceReport';
import noPermissionImage from '@assets/sprites/no-permission.png';
import defaultPageImage from '@assets/sprites/default.png';
import StaffAttendance from '@pages/StaffAttendance';
import HrFunction from '@pages/HrFunction';
import HrFunctionDetail from '@pages/HrFunction/Detail';
import { UserConfig } from '@api/type';
import store from '@reducers/index';

export interface RouteInfo {
  path: string;
  component: React.ReactNode;
  loader?: any;
}

export interface SidebarInfo {
  path: string;
  name?: string;
}

export const routerMap = {
  loginDenied: {
    path: '/login-denied',
    component: (
      <NoPermission
        imageUrl={noPermissionImage}
        tip="Login Denied"
      />
    ),
  },
  defalutPage: {
    path: '/default-page',
    component: (
      <NoPermission
        imageUrl={defaultPageImage}
        tip={`Please refer to the "Attendance" mini-app in your Seatalk mobile app for "My Attendance" and "My Team" information`}
      />
    ),
  },
  myAttendance: {
    path: '/my-attendance',
    component: <MyAttendance />,
  },
  myTeam: {
    path: '/my-team',
    component: <MyTeam />,
  },
  staffAttendance: {
    path: '/staff-attendance',
    component: <StaffAttendance />,
  },
  attendanceReport: {
    path: '/attendance-report',
    component: <AttendanceReport />,
  },
  hrFunction: {
    path: '/hr-function/list',
    component: <HrFunction />,
  },
  hrFunctionDetail: {
    path: '/hr-function/detail/:id',
    component: <HrFunctionDetail />,
  },
};

const routes = [
  {
    label: 'Attendance',
    isLabel: true,
    iconClassName: 'sprite-label sprite',
    children: [
      {
        isMyAttendancePage: true,
        path: routerMap.myAttendance.path,
        component: routerMap.myAttendance.component,
        label: 'My Attendance',
      },
      {
        isMyTeamPage: true,
        path: routerMap.myTeam.path,
        component: routerMap.myTeam.component,
        label: 'My Team',
      },
      {
        isAttendanceReportPage: true,
        path: routerMap.attendanceReport.path,
        component: routerMap.attendanceReport.component,
        label: 'Attendance Report',
      },
    ],
  },
  {
    label: 'Configuration',
    isLabel: true,
    iconClassName: 'sprite-configuration-icon sprite',
    children: [
      {
        isHrFunction: true,
        path: routerMap.hrFunction.path,
        component: routerMap.hrFunction.component,
        label: 'HR Data Access',
      },
      {
        isHrFunction: true,
        noShowInMenu: true,
        path: routerMap.hrFunctionDetail.path,
        component: routerMap.hrFunctionDetail.component,
      },
    ],
  },
  {
    isSearchStaff: true,
    path: routerMap.staffAttendance.path,
    component: routerMap.staffAttendance.component,
    noShowInMenu: true,
  },
];

const resolveRoutesInfo = (
  routerConfig: any[],
  hrPermission: boolean,
  userPermission: boolean,
  hrFunctionPermission: boolean,
  myTeamPermission: boolean
) => {
  let routesInfo: any[] = [];
  routerConfig.forEach(route => {
    if (route.isMyAttendancePage && userPermission) {
      routesInfo.push(route);
    } else if (route.isMyTeamPage && myTeamPermission) {
      routesInfo.push(route);
    } else if (
      (route.isAttendanceReportPage || route.isSearchStaff) &&
      hrPermission
    ) {
      routesInfo.push(route);
    } else if (route.isHrFunction && hrFunctionPermission) {
      routesInfo.push(route);
    }

    if (route.children) {
      routesInfo = [
        ...routesInfo,
        ...resolveRoutesInfo(
          route.children,
          hrPermission,
          userPermission,
          hrFunctionPermission,
          myTeamPermission
        ),
      ];
    }
  });
  return routesInfo;
};

const resolveSideBarInfo = (
  routerConfig: any[],
  hrPermission: boolean,
  userPermission: boolean,
  hrFunctionPermission: boolean,
  myTeamPermission: boolean
) => {
  const sidebars: any[] = [];
  routerConfig.forEach(route => {
    const menuItem = {} as any;
    if (route.isLabel) {
      menuItem.label = route.label;
    }
    if (route.iconClassName) {
      menuItem.iconClassName = route.iconClassName;
    }
    if (route.children) {
      menuItem.children = [];
      route.children.forEach((child: any) => {
        if (child.noShowInMenu) return;

        if (child.isMyAttendancePage && userPermission) {
          menuItem.children.push({
            label: child.label,
            path: child.path,
          });
        } else if (child.isMyTeamPage && myTeamPermission) {
          menuItem.children.push({
            label: child.label,
            path: child.path,
          });
        } else if (child.isAttendanceReportPage && hrPermission) {
          menuItem.children.push({
            label: child.label,
            path: child.path,
          });
        } else if (child.isHrFunction && hrFunctionPermission) {
          menuItem.children.push({
            label: child.label,
            path: child.path,
          });
        }
      });
    }

    if (menuItem.children?.length > 0) {
      sidebars.push(menuItem);
    }
  });
  return sidebars;
};

const getPagePermission = (
  functionConfig: UserConfig['functionConfig'],
  name: string
) => {
  const functionItem = functionConfig.filter(item => item.name === name)[0];
  if (!functionItem) return false;
  if (functionItem.hasPerm === 1) return true;
  return false;
};

export const generationRoutesInfo = async () => {
  try {
    const { code, data, message } = await getPermissionConfig();
    store.dispatch({
      type: 'SetUserConfig',
      payload: data,
    });

    if (code === ErrorCode.NoLoginDenied) {
      return {
        routers: [routerMap.loginDenied],
        sidebars: [
          {
            path: routerMap.loginDenied.path,
          },
        ],
        loginDenied: true,
      };
    } else if (code === ErrorCode.NoLogin) {
      Toast.error(message);
      logout();
      return {
        routers: [],
        sidebars: [],
      };
    }

    // 显示default page
    if (data.notificationType === 1) {
      return {
        routers: [routerMap.defalutPage],
        sidebars: [
          {
            path: routerMap.defalutPage.path,
          },
        ],
        defaultPage: true,
      };
    } else if (data.notificationType === 2) {
      Modal.alarm({
        title: 'Notification',
        content:
          'Please refer to the "Attendance" mini-app in your Seatalk mobile app for "My Attendance" and "My Team" information',
        onConfirm: () => {
          notificationConfirm();
        },
      });
    }

    const hrPermission = getPagePermission(data.functionConfig, 'HR');
    const userPermission = getPagePermission(data.functionConfig, 'General');
    const adminPermission = getPagePermission(data.functionConfig, 'Admin');
    const myTeamPermission = getPagePermission(data.functionConfig, 'Manager');

    const routers = resolveRoutesInfo(
      routes,
      hrPermission,
      userPermission,
      adminPermission,
      myTeamPermission
    );
    const sidebars = resolveSideBarInfo(
      routes,
      hrPermission,
      userPermission,
      adminPermission,
      myTeamPermission
    );

    return {
      routers,
      sidebars,
      hrPermission,
    };
  } catch {
    return {
      routers: [],
      sidebars: [],
    };
  }
};
