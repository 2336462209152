export enum CalendarRange {
  ThirtyDays = '30',
  FourteenDays = '14',
  SevenDays = '7',
}

export const CalendarRangeMap: any = {
  0: CalendarRange.SevenDays,
  1: CalendarRange.FourteenDays,
  2: CalendarRange.ThirtyDays,
};

export const selectAllValue = -1;

export enum SubordinateViewTypeEnum {
  DirectSubordinates = 1,
  IndirectSubordinates = 2,
  AllSubordinates = 3,
}

// 日期类型：0=低于时长，1=等于时长，2=高于时长，3=缺席 4
export enum PeriodTypeEnum {
  BelowStandard = 0,
  Standard = 1,
  LongDay = 2,
  NoTap = 3,
  NoEnough = 4,
}

export const ColorType = {
  [PeriodTypeEnum.BelowStandard]: '#6ec7ff',
  [PeriodTypeEnum.Standard]: '#1b92f5',
  [PeriodTypeEnum.LongDay]: '#005ac0',
  [PeriodTypeEnum.NoTap]: '#F5222D',
  [PeriodTypeEnum.NoEnough]: '#f5881b',
};

const now = new Date();
export const rangeShortcuts = [
  {
    text: 'Last Week',
    value: {
      startDate: new Date(now.getTime() - 7 * 24 * 3600 * 1000),
      endDate: now,
    },
  },
  {
    text: 'Last Two Weeks',
    value: {
      startDate: new Date(now.getTime() - 14 * 24 * 3600 * 1000),
      endDate: now,
    },
  },
  {
    text: 'Last Month',
    value: {
      startDate: new Date(now.getTime() - 30 * 24 * 3600 * 1000),
      endDate: now,
    },
  },
  {
    text: 'Last Half Year',
    value: {
      startDate: new Date(now.getTime() - 180 * 24 * 3600 * 1000),
      endDate: now,
    },
  },
];
