import {
  ConfigAction,
  ConfigState,
  SetHrisDomainActionType,
  SetUserConfigActionType,
} from './type';

const initialState: ConfigState = {
  userEmail: '',
  functionConfig: [],
  notificationType: -1,
  legendConfig: {
    softUpperLimit: 0,
    softLowerLimit: 0,
    durationUpperLimit: 0,
    durationLowerLimit: 0,
  },
  hrisDomain: '',
};

export function configReducer(state = initialState, action: ConfigAction) {
  switch (action.type) {
    case SetUserConfigActionType:
      return {
        ...state,
        ...action.payload,
      };
    case SetHrisDomainActionType:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
}
