import {
  addExemption,
  addMemo,
  getAttendanceGroupFeeds,
  getAttendanceMemoConfig,
  getAttendanceStaffFeeds,
} from '@api/index';
import { AttendanceGroupFeedResponse } from '@api/type';
import UserCalendar from '@components/EchartCalendar';

import { UserInfoState } from '@reducers/userInfo/type';
import React, { useEffect, useRef, useState } from 'react';
import styles from './index.module.scss';
import { CalendarRange, CalendarRangeMap } from '@contants/index';
import UserCalendarFilter from '@components/UserCalender/UserCalendarFilter';
import { useLocation } from 'react-router-dom';
import { Button, DatePicker } from 'shopee-ui-react';
import Legends from '@components/Legend';
import { cloneDeep } from 'lodash-es';

export interface StaffAttendanceProps {
  feedId?: number;
  defaultRange?: number;
}

const StaffAttendance = (props: StaffAttendanceProps) => {
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const [data, setData] = useState<AttendanceGroupFeedResponse>({});
  const [loading, setLoading] = useState(false);
  const [loadingMore, setLoadingMore] = useState(false);
  const [options, setOptions] = useState<any>({
    from: 0,
    days: CalendarRangeMap[props.defaultRange || 2],
    staffs:
      params
        .get('staffs')
        ?.split(',')
        .map(id => Number(id)) || [],
  });

  const handleAddExemption = async (staff: any) => {
    let op = 1;
    if (staff.exempt) {
      op = 0;
    }
    const { ret, exemptBy, exemptDate } = await addExemption({
      id: staff.id,
      op,
    });
    if (ret === 0) {
      const info = data.attendance?.filter(item => item.id === staff.id)[0];
      if (info) {
        info.exempt = !staff.exempt;
        info.exemptBy = exemptBy;
        info.exemptDate = exemptDate;
      }
      setData(cloneDeep(data));
    }
  };

  const handleRangeChange = (range: string) => {
    const listOptions = {
      ...options,
      from: 0,
      days: range,
    };
    getAttendanceList(listOptions);
    setOptions({
      ...options,
      days: range,
    });
    setLoadingMore(false);
  };

  const getAttendanceList = async (listOptions: any, isLoadMore = false) => {
    setLoading(true);
    const res = await getAttendanceStaffFeeds(listOptions);
    if (!isLoadMore) {
      setData(res);
    } else {
      res.attendance = (data.attendance || []).concat(res.attendance || []);
      setData(res);
    }
    console.log('res', res);
    setLoading(false);
  };

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const newOptions = {
      ...options,
      staffs:
        params
          .get('staffs')
          ?.split(',')
          .map(id => Number(id)) || [],
    };
    getAttendanceList(newOptions);
    setOptions(newOptions);
  }, [location.search]);

  useEffect(() => {
    performance.mark('FCP');
  }, []);

  return (
    <div className={styles.staff_attendance}>
      <div className={styles.header_wrapper}>
        <div className={styles.legend_wrapper}>
          <Legends />
        </div>
        <div className={styles.filter_wrapper}>
          <div className={styles.filter_item}>
            <div className={styles.range_wrapper}>
              <div
                onClick={() => handleRangeChange(CalendarRange.SevenDays)}
                className={
                  options.days === CalendarRange.SevenDays
                    ? `${styles.range_item} ${styles.selected}`
                    : styles.range_item
                }>
                7D
              </div>
              <div
                onClick={() => handleRangeChange(CalendarRange.FourteenDays)}
                className={
                  options.days === CalendarRange.FourteenDays
                    ? `${styles.range_item} ${styles.selected}`
                    : styles.range_item
                }>
                14D
              </div>
              <div
                onClick={() => handleRangeChange(CalendarRange.ThirtyDays)}
                className={
                  options.days === CalendarRange.ThirtyDays
                    ? `${styles.range_item} ${styles.selected}`
                    : styles.range_item
                }>
                30D
              </div>
            </div>
          </div>
        </div>
      </div>
      <UserCalendar
        {...data}
        calendar={data.calender}
        loading={loading}
        addExemption={handleAddExemption}
      />
    </div>
  );
};

export default StaffAttendance;
