import {
  AttendanceGroupFeedResponse,
  DayInfo,
  MyAttendanceResponse,
  MyTeamAttendanceListResponse,
} from '@api/type';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import * as echarts from 'echarts';
import { useSelector } from 'react-redux';
import { UserInfoState } from '@reducers/userInfo/type';
import { ConfigState } from '@reducers/config/type';
import { DefaultPage, Popover, Spin, Tooltip } from 'shopee-ui-react';
import styles from './index.module.scss';
import { ColorType, PeriodTypeEnum } from '@contants/index';

interface EChartCalendarProps extends MyAttendanceResponse {
  hrisDomain?: string;
  loading?: boolean;
  loadingMore?: boolean;
  addExemption?: (staff: any) => void;
  openAddMemo?: (day: any) => void;
  loadMore?: () => void;
  myAttendance?: boolean;
  hasMore?: boolean;
}

const EChartCalendar: React.FC<EChartCalendarProps> = ({
  calendar = [],
  attendance = [],
  loading: dataLoading = false,
  myAttendance,
  addExemption,
  openAddMemo,
}) => {
  const userInfo = useSelector(
    (store: { userInfo: UserInfoState }) => store.userInfo
  );

  const config = useSelector((store: { config: ConfigState }) => store.config);
  const chartRef = useRef<HTMLDivElement>(null);
  const chartWrapperRef = useRef<HTMLDivElement>(null);
  const [chartContainerWidth, setChartContainerWidth] = useState(0);

  const chartInstance = useRef<echarts.ECharts>();
  const [hoverStaffs, setHoverStaffs] = useState<number[]>([]);
  const [isChartLoading, setIsChartLoading] = useState(true);
  // 添加新的状态和引用
  const headerRef = useRef<HTMLDivElement>(null);
  const [monthHeaders, setMonthHeaders] = useState<
    { month: string; width: number }[]
  >([]);

  // 防止 canvas 突然出现
  const loading = useMemo(() => {
    return dataLoading || isChartLoading;
  }, [dataLoading, isChartLoading]);

  const loadingTip = useMemo(() => {
    if (dataLoading) {
      return 'Loading Data...';
    }
    if (isChartLoading) {
      return 'Initializing Chart...';
    }
  }, [dataLoading, isChartLoading]);

  const noData = useMemo(() => {
    return !attendance.length;
  }, [attendance]);

  const handleMouseOver = (staff: any) => {
    if (!hoverStaffs.includes(staff.id)) {
      setHoverStaffs([...hoverStaffs, staff.id]);
    }
  };

  const handleMouseOut = (staff: any) => {
    const index = hoverStaffs.indexOf(staff.id);
    if (index > -1) {
      hoverStaffs.splice(index, 1);
      setHoverStaffs([...hoverStaffs]);
    }
  };

  const getTipName = (day: DayInfo) => {
    if (day.isLeave === 1 || day.isLeave === 2) return 'L';
    if (day.isHoliday) return 'P';
    if (day.isWeekend) return 'S';
    if (day.isOnBusinessTravel === 1) return 'T';
    return '';
  };

  const isMissingDay = (day: DayInfo, staff: any) => {
    return (
      !day.today &&
      !staff.exempt &&
      !day.isWeekend &&
      !day.isHoliday &&
      !day.isLeave &&
      !day.isOnBusinessTravel &&
      day.in === '' &&
      day.out === ''
    );
  };

  const isIncompleteDay = (day: DayInfo, staff: any) => {
    return (
      !day.today &&
      !staff.exempt &&
      !day.isWeekend &&
      !day.isHoliday &&
      !day.isLeave &&
      !day.isOnBusinessTravel &&
      ((day.in === '' && day.out !== '') || (day.in !== '' && day.out === ''))
    );
  };

  const isExemptDay = (day: DayInfo, staff: any) => {
    return (
      !day.today &&
      staff.exempt &&
      !day.isWeekend &&
      !day.isHoliday &&
      !day.isLeave &&
      !day.isOnBusinessTravel &&
      (day.in === '' || day.out === '')
    );
  };

  // 在 calculateChartDimensions 中添加计算月份宽度的逻辑
  const calculateChartDimensions = useCallback(() => {
    const totalDays = calendar.reduce(
      (acc, month) => acc + month.days.length,
      0
    );
    const barWidth = 22;
    const chartWidth = totalDays * barWidth;

    const gridHeight = 36;
    const gridGap = 0;
    const gridLeft = 0;
    const gridRight = 0;
    const topOffset = 0;
    const totalHeight =
      topOffset + (gridHeight + gridGap) * attendance.length - gridGap + 4;

    const monthHeaders = calendar.map(month => ({
      month: month.month,
      width: month.days.length * barWidth, // 增加单个日期的宽度
    }));
    setMonthHeaders(monthHeaders);

    return {
      chartWidth,
      chartHeight: totalHeight,
      gridHeight,
      gridGap,
      gridLeft,
      gridRight,
      topOffset,
    };
  }, [attendance, calendar]);

  const calculateContainerWidth = useCallback(() => {
    const nameContainerWidth = 160;
    const { chartWidth } = calculateChartDimensions();
    const echartWidth = nameContainerWidth + chartWidth;
    if (chartWrapperRef.current) {
      setChartContainerWidth(
        echartWidth > chartWrapperRef.current.clientWidth
          ? echartWidth
          : chartWrapperRef.current.clientWidth
      );
      return;
    }
    setChartContainerWidth(echartWidth);
  }, [chartWrapperRef.current, attendance]);

  const initChart = useCallback(() => {
    if (!chartRef.current) return;

    const dimensions = calculateChartDimensions();

    // 设置容器尺寸
    chartRef.current.style.width = `${
      dimensions.chartWidth + dimensions.gridLeft + dimensions.gridRight
    }px`;
    // 只有当计算的高度大于容器最小高度才设置
    const minHeight = 360;
    chartRef.current.style.height =
      dimensions.chartHeight > minHeight
        ? `${dimensions.chartHeight}px`
        : `${minHeight}px`;

    const grids = attendance.map((_, index) => ({
      left: dimensions.gridLeft,
      right: dimensions.gridRight,
      height: dimensions.gridHeight,
      top:
        dimensions.topOffset +
        (dimensions.gridHeight + dimensions.gridGap) * index,
    }));

    const option = {
      tooltip: {
        trigger: 'item',
        confine: false, // 设置为 false，允许 tooltip 超出画布范围
        transitionDuration: 0, // 不要过渡效果，不然边缘显示会出现滚动条
        formatter(params: any) {
          const data = params.data;
          if (!data) return;

          // 通过 name 找到对应的 staff 数据
          const staffName = params.seriesName.split('_')[0];
          const staff = attendance.find(s => s.name === staffName);
          if (!staff) return;

          const content = [];

          // 日期和工时
          if (!data.today) {
            content.push(
              `<p style="margin: 0; line-height: 1.5">${data.date}&nbsp;&nbsp;${
                data.hours
              }${data.hours !== '' ? 'hrs' : ''}</p>`
            );
          }

          // 打卡时间
          if (data.in && data.out) {
            content.push(
              `<p style="margin: 0; line-height: 1.5">${data.in} - ${data.out}</p>`
            );
          }

          // 假期备注
          if (data.holidayRemark) {
            content.push(
              `<p style="margin: 0; line-height: 1.5">${data.holidayRemark}</p>`
            );
          }

          // 请假备注
          if (data.leaveRemark) {
            content.push(
              `<p style="margin: 0; line-height: 1.5">${data.leaveRemark}</p>`
            );
          }

          // 出差备注
          if (data.bizTravelRemark) {
            content.push(
              `<p style="margin: 0; line-height: 1.5">${data.bizTravelRemark}</p>`
            );
          }

          // Missing 或 Exempted 状态
          if (isMissingDay(data, staff)) {
            content.push('<p style="margin: 0; line-height: 1.5">Missing</p>');
          }

          if (isExemptDay(data, staff)) {
            content.push(
              '<p style="margin: 0; line-height: 1.5">Exempted.</p>'
            );
          }

          // Incomplete 状态
          if (isIncompleteDay(data, staff)) {
            content.push(
              `<p style="margin: 0; line-height: 1.5">${
                data.exempt ? 'Exempted.' : 'Incomplete'
              }</p>`
            );
          }

          // 今日数据提示
          if (data.today) {
            content.push(
              `<p style="margin: 0; line-height: 1.5">Today's data will be ready at 8am tomorrow. But you may add remarks now.</p>`
            );
          }

          // 备注信息
          if (!data.memo && userInfo.pk === staff.id) {
            content.push(
              `<p style="margin: 0; line-height: 1.5; font-weight: 500; font-style: italic">Click to remark</p>`
            );
          }

          if (data.memo) {
            content.push(
              `<p style="margin: 0; line-height: 1.5">${data.memo}</p>`
            );
          }

          return `<div class="${
            styles.bar_info
          }" style="padding: 4px">${content.join('')}</div>`;
        },
      },
      grid: grids,
      // 添加装饰线配置，月份展示
      graphic: attendance
        .map((_, index) => [
          // 只在第一行显示上边框
          // ...(index === 0 ? [{
          //   type: 'line',
          //   z: 100,
          //   left: dimensions.gridLeft - 2,
          //   right: dimensions.gridRight + 2,
          //   top: dimensions.topOffset + (dimensions.gridHeight + dimensions.gridGap) * index - 2,
          //   shape: {
          //     x1: 0,
          //     y1: 0,
          //     x2: dimensions.chartWidth,
          //     y2: 0
          //   },
          //   style: {
          //     stroke: '#d9d9d9',
          //     lineWidth: 1
          //   }
          // }] : []),
          // // 下边框
          // {
          //   type: 'line',
          //   z: 100,
          //   left: dimensions.gridLeft - 2,
          //   right: dimensions.gridRight + 2,
          //   top: dimensions.topOffset + dimensions.gridHeight + (dimensions.gridHeight + dimensions.gridGap) * index - 3,
          //   shape: {
          //     x1: 0,
          //     y1: 0,
          //     x2: dimensions.chartWidth,
          //     y2: 0
          //   },
          //   style: {
          //     stroke: '#d9d9d9',
          //     lineWidth: 1
          //   }
          // },
          // 左边框
          // {
          //   type: 'line',
          //   z: 100,
          //   left: dimensions.gridLeft - 2,
          //   top: dimensions.topOffset + (dimensions.gridHeight + dimensions.gridGap) * index,
          //   shape: {
          //     x1: 0,
          //     y1: 0,
          //     x2: 0,
          //     y2: dimensions.gridHeight
          //   },
          //   style: {
          //     stroke: '#d9d9d9',
          //     lineWidth: 1
          //   }
          // },
          // // 右边框
          // {
          //   type: 'line',
          //   z: 100,
          //   left: dimensions.chartWidth + dimensions.gridLeft + 2,  // 修改这里，使用 left 而不是 right
          //   top: dimensions.topOffset + (dimensions.gridHeight + dimensions.gridGap) * index,
          //   shape: {
          //     x1: 0,
          //     y1: 0,
          //     x2: 0,
          //     y2: dimensions.gridHeight
          //   },
          //   style: {
          //     stroke: '#d9d9d9',
          //     lineWidth: 1
          //   }
          // }
        ])
        .flat(),
      xAxis: attendance
        .map((_, index) => [
          {
            type: 'category',
            gridIndex: index,
            position: 'top',
            show: false,
            data: calendar.map(month => ({
              value: month.month,
              textStyle: {
                fontSize: 12,
                fontWeight: 'bold',
              },
            })),
            axisLabel: {
              interval: 0,
              margin: 24,
            },
            axisTick: { show: false },
            axisLine: { show: false },
          },
          {
            type: 'category',
            gridIndex: index,
            position: 'bottom',
            show: false,
            z: 99,
            data: calendar.reduce((acc: any, month: any) => {
              return [
                ...acc,
                ...month.days.map((day: any) => (day.isWeekend ? '' : day.day)),
              ];
            }, []),
            axisLabel: {
              show: index === 0,
            },
            axisTick: {
              show: true,
              alignWithLabel: true,
              interval: 0, // 添加这行，确保每个刻度都显示
              length: 4, // 添加这行，控制刻度线长度
            },
            boundaryGap: true,
            axisLine: { show: false },
            splitLine: { show: false },
          },
        ])
        .flat(),
      yAxis: attendance.map((staff, index) => ({
        type: 'value',
        gridIndex: index,
        position: 'left',
        axisLabel: { show: false },
        axisTick: { show: false },
        splitLine: { show: false },
        min: 0,
        max: 24, // 设置最大值为 24
      })),
      series: attendance
        .map((staff, index) => {
          return [
            // 状态图标系列
            {
              name: staff.name + '_status',
              type: 'scatter',
              xAxisIndex: index * 2 + 1,
              yAxisIndex: index,
              z: 4,
              color: 'transparent',
              symbolSize: [16, 16],
              symbolOffset: [0, -17],
              data: staff.days.flatMap((day, dayIndex) => {
                const statusIcons = [];

                // 今日状态
                if (day.today) {
                  statusIcons.push({
                    value: [dayIndex, 0],
                    symbol: 'rect',
                    symbolSize: [14, 28],
                    symbolOffset: [0, -18],
                    itemStyle: {
                      color: 'transparent',
                      borderColor: '#666',
                      borderType: 'dashed',
                      borderWidth: 1,
                    },
                    ...day,
                  });
                }

                // 修改状态标识 (新增)
                if (day.correctType) {
                  // 假设后端返回的数据中有 isModified 字段
                  statusIcons.push({
                    value: [dayIndex, 0],
                    symbol:
                      'path://M8.54912 0.418663C9.10734 -0.139555 10.0124 -0.139554 10.5706 0.418665L11.5813 1.42941C12.1396 1.98763 12.1396 2.89267 11.5813 3.45089L10.2993 4.73291L7.26709 1.70069L8.54912 0.418663ZM6.25635 2.71143L9.28858 5.74365L4.05239 10.9798C3.85775 11.1745 3.61107 11.3088 3.34196 11.3666L0.432664 11.9918C0.179062 12.0463 -0.0462892 11.8209 0.00820872 11.5673L0.633405 8.65804C0.691234 8.38893 0.825532 8.14225 1.02016 7.94762L6.25635 2.71143Z', // 修改为更圆润的笔形图标
                    symbolSize: 6,
                    symbolOffset: [8, -31],
                    itemStyle: {
                      color: '#ADB2BA',
                    },
                    tooltip: {
                      formatter: `Employee has made a correction to the attendance for this day.<br/>Kindly check details via the Attendance mini app.`,
                      borderColor: 'transparent',
                    },
                    ...day,
                  });
                }

                // Missing 状态
                if (isMissingDay(day, staff)) {
                  statusIcons.push({
                    value: [dayIndex, 0],
                    symbol: `image://${require('../../assets/sprites/missing.png')}`,
                    itemStyle: {
                      color: 'transparent',
                    },
                    ...day,
                  });
                }

                // Incomplete 状态
                if (isIncompleteDay(day, staff)) {
                  statusIcons.push({
                    value: [dayIndex, 0],
                    symbol: `image://${require('../../assets/sprites/missing.png')}`,
                    ...day,
                  });
                }

                // Exempt 状态
                if (isExemptDay(day, staff)) {
                  statusIcons.push({
                    value: [dayIndex, 0],
                    symbol:
                      'path://M433.1 657.7c12.7 17.7 39 17.7 51.7 0l210.6-292c3.8-5.3 0-12.7-6.5-12.7H642c-10.2 0-19.9 4.9-25.9 13.3L459 584.3l-71.2-98.8c-6-8.3-15.6-13.3-25.9-13.3H315c-6.5 0-10.3 7.4-6.5 12.7l124.6 172.8z',
                    symbolSize: 12,
                    itemStyle: {
                      color: '#cdcdcd',
                    },
                    tooltip: {
                      borderColor: 'transparent', // 添加这个配置来移除 hover 时的边框
                    },
                    ...day,
                  });
                }

                // 备注点
                if (day.memo) {
                  const isDotWhite = day.in !== '' && day.out !== '';
                  statusIcons.push({
                    value: [dayIndex, 0],
                    symbol: 'circle',
                    symbolSize: 4,
                    symbolOffset: [0, -4],
                    itemStyle: {
                      color: isDotWhite ? '#fff' : '#ff4d4f',
                    },
                    tooltip: {
                      borderColor: 'transparent', // 添加这个配置来移除 hover 时的边框
                    },
                    ...day,
                  });
                }

                return statusIcons.length
                  ? statusIcons
                  : [
                      {
                        value: [dayIndex, 0],
                        symbol: 'none',
                        color: '#fff',
                        ...day,
                      },
                    ];
              }),
            },
            // 为了 hover 效果
            {
              name: staff.name,
              type: 'bar',
              xAxisIndex: index * 2 + 1,
              yAxisIndex: index,
              barWidth: 20,
              z: 2,
              itemStyle: {
                color: 'transparent',
              },
              data: staff.days.map(day => ({
                value: 24,
                ...day,
              })),
            },
            // 灰色背景色
            {
              name: staff.name,
              type: 'bar',
              xAxisIndex: index * 2 + 1,
              yAxisIndex: index,
              barWidth: 20,
              z: 1,
              emphasis: {
                disabled: true,
              },
              data: staff.days.map(day => {
                const isSpecialDay =
                  day.isWeekend ||
                  day.isLeave === 1 ||
                  day.isLeave === 2 ||
                  day.isOnBusinessTravel === 1 ||
                  day.isHoliday;
                return {
                  value: 24,
                  itemStyle: {
                    color: isSpecialDay ? '#eee' : '#fff',
                  },
                  ...day,
                };
              }),
            },
            // 时长数据
            {
              name: staff.name,
              type: 'bar',
              xAxisIndex: index * 2 + 1,
              yAxisIndex: index,
              barWidth: 20,
              z: 1,
              showBackground: true,
              backgroundStyle: {
                color: '#fefefe',
              },
              barGap: '-100%',
              barCategoryGap: '-100%',
              data: staff.days.map(day => {
                const color =
                  ColorType[day.periodType] ||
                  ColorType[PeriodTypeEnum.BelowStandard];
                const tip = getTipName(day);
                const isHalfLeave = day.isLeave === 2;
                const notShowHours =
                  isMissingDay(day, staff) ||
                  isIncompleteDay(day, staff) ||
                  isExemptDay(day, staff);

                return {
                  value: notShowHours ? 0 : day.hours || 0,
                  itemStyle: {
                    // 半天休假时设置渐变色
                    color: isHalfLeave
                      ? {
                          type: 'linear',
                          x: 0,
                          y: 0,
                          x2: 0,
                          y2: 1,
                          colorStops: [
                            {
                              offset: 0,
                              color: color || 'transparent',
                            },
                            {
                              offset: 0.5,
                              color: color || 'transparent',
                            },
                            {
                              offset: 0.5,
                              color: '#eee',
                            },
                            {
                              offset: 1,
                              color: '#eee',
                            },
                          ],
                        }
                      : color,
                  },

                  label: {
                    show: !!tip,
                    position: 'bottom',
                    formatter: tip,
                    color: Number(day.hours) > 6 ? '#fff' : '#ccc',
                    fontSize: 12,
                    fontWeight: 'bold',
                    distance: -18,
                  },
                  ...day,
                };
              }),
            },
          ];
        })
        .flat(),
    };

    // 初始化时设置设备像素比
    chartInstance.current = echarts.init(chartRef.current, null, {
      devicePixelRatio: 2, // 使用更高的像素比
      renderer: 'canvas', // 明确指定渲染器
      useDirtyRect: false, // 禁用脏矩形优化以获得更好的渲染质量
    });

    chartInstance.current.setOption(option);

    chartInstance.current.on('click', function (params: any) {
      const data = params.data;
      if (openAddMemo && data) {
        openAddMemo(data);
      }
    });
    // 监听图表渲染完成事件
    chartInstance.current.on('finished', () => {
      setIsChartLoading(false);
    });
  }, [attendance, calendar]);

  useEffect(() => {
    initChart();
    if (!attendance.length) {
      setIsChartLoading(false);
    }
    return () => {
      chartInstance.current?.dispose();
    };
  }, [attendance, calendar]);

  useEffect(() => {
    if (dataLoading) {
      setIsChartLoading(true);
    }
  }, [dataLoading]);

  useEffect(() => {
    calculateContainerWidth();
    const handleResize = () => {
      calculateContainerWidth();
      chartInstance.current?.resize();
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [attendance]);

  if (noData && !loading) {
    return <DefaultPage style={{ padding: 64 }}>No Data</DefaultPage>;
  }

  return (
    <div
      ref={chartWrapperRef}
      style={{
        overflowY: myAttendance || attendance.length > 3 ? 'auto' : 'visible',
      }}
      className={styles.chart_wrapper}>
      <div
        style={{ display: loading ? 'block' : 'none' }}
        className={styles.chart_loading}>
        <Spin tips={loadingTip} />
      </div>
      <div style={{ display: !loading ? 'block' : 'none' }}>
        <div
          className={styles.header_fixed}
          ref={headerRef}>
          <div className={styles.header_holder}>
            <div className={styles.month_holder}></div>
            <div className={styles.day_holder}></div>
          </div>
          <div className={styles.month_and_day}>
            <div className={styles.month_header}>
              {monthHeaders.map((month, index) => (
                <div
                  key={index}
                  className={styles.month_cell}
                  style={{
                    width: month.width,
                    background: index % 2 === 0 ? '#f9fafb' : '#f3f4f6',
                  }}>
                  {month.month}
                </div>
              ))}
            </div>
            <div className={styles.day_header}>
              {calendar.map((month, monthIndex) =>
                month.days.map((day, dayIndex) => (
                  <div
                    key={`${month.month}-${dayIndex}`}
                    className={styles.day_cell}
                    style={{
                      background: monthIndex % 2 === 0 ? '#f9fafb' : '#f3f4f6',
                    }}>
                    {day.isWeekend ? '' : day.day}
                  </div>
                ))
              )}
            </div>
          </div>
        </div>

        <div
          style={{ width: chartContainerWidth }}
          className={styles.content_wrapper}>
          <div className={styles.names_container}>
            {attendance.map((staff, index) => (
              <div
                key={index}
                className={`${styles.name_item}`}
                onMouseOver={() => handleMouseOver(staff)}
                onMouseOut={() => handleMouseOut(staff)}>
                <span className={styles.staff_name}>
                  <a
                    rel="noreferrer"
                    href={
                      userInfo.fields.canAccessProfile
                        ? `${config.hrisDomain}/#/detail/${staff.id}`
                        : ''
                    }
                    target="_blank">
                    <Tooltip content={staff.name}>
                      <em>{staff.name}</em>
                    </Tooltip>
                  </a>
                </span>
                {userInfo.fields.canExempt &&
                  hoverStaffs.includes(staff.id) &&
                  !staff.exempt && (
                    <div
                      className={`sprite-bag sprite ${styles['exempt-icon']}`}
                      onClick={() => addExemption && addExemption(staff)}
                    />
                  )}
                {userInfo.fields.canExempt && staff.exempt ? (
                  <div
                    className={`sprite-bag-exempt sprite ${styles['exempt-icon']}`}
                    onClick={() => addExemption && addExemption(staff)}></div>
                ) : null}
                {!userInfo.fields.canExempt && staff.exempt ? (
                  <div
                    className={`sprite-bag-exempt sprite-bag-exempt-disable sprite ${styles['exempt-icon']} ${styles['exempt-icon-disable']}`}
                    onClick={() => addExemption && addExemption(staff)}></div>
                ) : null}
                <div
                  style={{
                    top: 36 * (index + 1),
                    minWidth: chartContainerWidth,
                    pointerEvents: 'none', // 添加这行来阻止 hover 事件
                  }}
                  className={styles.bottom_border}></div>
              </div>
            ))}
          </div>

          <div className={styles.chart_content}>
            <div
              ref={chartRef}
              className={styles.chart_container}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default EChartCalendar;
